<template>
  <div class="about_boxs">
    <header class="about_head">
      <img src="@/assets/about/about.png" class="img_css" />
    </header>
    <main class="about_main">
      <div class="item_main">
        <img src="@/assets/about/introduction.png" class="item_img" />
        <div class="text_conent">
          &#12288;&#12288;北京新隆福文化投资有限公司（简称“新隆福公司”）成立于2012年8月，由北京国资公司和北京东方文化资产经营公司按7:3出资比例共同出资成立，负责具体实施隆福寺地区风貌保护和城市更新项目。
          <br/>&#12288;&#12288;隆福寺项目位于东城区东四地区，占地面积约15.5万平米，包括功能区和风貌区，其中，功能区占地面积约6万平方米，建筑面积约18万平米；风貌区占地面积约9.5万平方米。
          <br/>&#12288;&#12288;隆福寺项目定位为“传统文化与现代文明交相辉映，中华文化与世界文明协调共融的首都文化新中心”。核心元素包括：古今隆福、开放首都、国际地标、文化四合。
          <br/>&#12288;&#12288;隆福寺项目将落实高质量发展要求，服务首都城市战略定位，服务“四个中心”功能建设，满足首都人民的多样化文化需求，打造时尚化、智能化的数字标杆示范商圈及文化消费新地标，努力建设成为全国文化中心的示范区域。
        </div>
      </div>
      <div class="item_main">
        <img src="@/assets/about/company_img.png" class="company_img" />
      </div>
    </main>
  </div>
</template>

<script setup>
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .about_main{
    width: 1200px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .about_main{
    width: 950px !important;
  }
}
@media only screen and (max-width: 960px) {
  .about_main{
    width: 850px !important;
  }
}
@media only screen and (max-width: 768px) {
  .about_main{
    width: 680px !important;
  }
}
@media only screen and (max-width: 640px) {
  .about_main{
    width: 560px !important;
  }
}
</style>

<style lang="scss" scoped>
.about_boxs {
    font-family: PingFang SC;
    z-index: 1;
    max-width: 1920px;
    margin: 0 auto;
  .img_css {
    width: 100%;
    display: flex;
  }

  .about_head {
    // border: 30px solid #ffda00;
  }

  .about_main {
    padding: 223px 0 311px;
    display: flex;
    width: 1400px;
    margin: 0 auto;

    .item_main {
      flex: 1;

      &:last-child {
        margin-left: 78px;
      }

      .text_conent {
        margin-top: 49px;
        font-size: 22px;
        font-weight: 300;
        color: #333333;
        font-family: PingFangSC-Light;
      }

      .company_img {
        width: 100%;
        height: 100%;
      }

    }

    .item_img {
      width: 429px;
      height: 76px;
    }
  }
}
</style>
